export const LOAD_STATUS = {
  PLAN: 1,
  AVAILABLE: 2,
  BOOKED: 3,
  CONFIRMED: 4,
  PICKED: 5,
  DELIVERED: 6,
  CLAIM: 7,
  INVOICED: 8,
  CARRIER_PAID: 9,
  PICKUP_ON_TRACK: 10,
  PICKUP_OFF_TRACK: 11,
  DELIVERY_ON_TRACK: 12,
  DELIVERY_OFF_TRACK: 13,
  READY_TO_BILL: 14,
  NEEDS_APPT: 15,
  LOADING: 16,
  UNLOADING: 17,
  CUSTOMER_RECEIVED: 18,
  DRAFT: 19,
  QUOTED: 20,
}

export const CREATE_METHOD = {
  TMS_USER: 1,
  CUSTOMER_USER: 2,
  API_THIRD_PARTY: 3,
  CSV_IMPORT_TMS: 4,
  CUSTOMER_IMPORT_JOB: 5,
  EXO_EDI: 6,
}

export const NETWORK_TYPES = {
  DEFAULT: 1,
  NON_EXO: 2,
  PRIVATE: 3,
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"max-width":"1600px"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"mt-0 pl-0",attrs:{"min-height":"390"}},[_c('v-card-title',{staticClass:"blue--text text--lighten-2"},[_vm._v(" Quick Actions ")]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-btn',{staticClass:"mt-2",attrs:{"small":"","color":"red"},on:{"click":_vm.removeExternalLoadPostings}},[_vm._v(" Remove External Load Postings ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"mt-0",attrs:{"min-height":"390"}},[_c('v-card-title',{staticClass:"blue--text text--lighten-2"},[_vm._v(" Stats ")]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',{staticClass:"col-md-12 mb-0 pb-0 pl-0"},[_c('b',[_vm._v("Total Shipments:")]),_vm._v(" "+_vm._s(_vm.customerStats.totalShipment)+" ")]),_c('p',{staticClass:"col-md-12 mb-0 pb-0 pl-0"},[_c('b',[_vm._v("Margin %:")]),_vm._v(" "+_vm._s(_vm.customerStats.averageMargin)+" ")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"mt-0",attrs:{"min-height":"390"}},[_c('v-card-title',{staticClass:"blue--text text--lighten-2"},[_vm._v(" Shipments "),_c('v-spacer'),_c('v-btn',{staticClass:"float-right blue--text text--lighten-2",attrs:{"small":"","text":""},on:{"click":_vm.viewAllShipments}},[_vm._v(" View All ")])],1),_c('v-card-text',{staticClass:"pb-1"},[(_vm.recentShipments !== null)?_c('div',[_vm._l((_vm.recentShipments),function(item,key){return [_c('v-row',{key:key,style:(key !== 4 ? 'border-bottom: 1px solid #E0E0E0' : '')},[_c('p',{staticClass:"col-md-12 mb-0 pb-0 font-weight-bold"},[_vm._v(" "+_vm._s(item.id)+" ")]),_c('p',{staticClass:"col-md-4 mb-0 pb-1 text-left"},[(
                      item.shipper.city && item.shipper.stateProvinceRegion
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.formatLocation( item.shipper.city, item.shipper.stateProvinceRegion ))+" ")]):_vm._e()]),_c('v-icon',{staticClass:"col-md-1 mb-0 pb-1 text-left"},[_vm._v(" mdi-arrow-right-bold ")]),_c('p',{staticClass:"col-md-4 mb-0 pb-1 text-left"},[(
                      item.consignee.city &&
                      item.consignee.stateProvinceRegion
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.formatLocation( item.consignee.city, item.consignee.stateProvinceRegion ))+" ")]):_vm._e()]),_c('p',{staticClass:"col-md-3 mb-0 pb-1 text-center"},[(item.customerPrice)?_c('span',[_vm._v(" $ "+_vm._s(item.customerPrice)+" ")]):_vm._e()])],1)]})],2):_vm._e()])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-0"},[_c('v-card-title',{staticClass:"blue--text text--lighten-2 pb-0"},[_vm._v(" Relationships ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"10"}},[_c('v-autocomplete',{attrs:{"items":_vm.locationList,"loading":_vm.loading,"search-input":_vm.search,"color":"info","clearable":"","item-text":"name","item-value":"id","label":"Location","return-object":"","required":"","close-on-content-click":"","item-width":"200","filter":(v) => v},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"click:clear":function($event){return _vm.removeLocation()}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_vm._v(" "+_vm._s(_vm.shortLocationName(data.item.name))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                            data.item.city +
                            ', ' +
                            data.item.state_province_region
                          )}})],1)]}}]),model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"while--text blue text--lighten-2 font-weight-bold float-left mt-5",class:_vm.$vuetify.breakpoint.smAndDown ? 'float-right' : '',attrs:{"small":""},on:{"click":function($event){return _vm.addToLocation()}}},[_vm._v(" Add ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"pt-0 mb-2",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchLocation),callback:function ($$v) {_vm.searchLocation=$$v},expression:"searchLocation"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.locationHeaders,"items":_vm.currentCustomer.locations,"calculate-widths":"","fixed-header":"","items-per-page":5,"footer-props":{
                      'items-per-page-options': [5],
                    },"search":_vm.searchLocation},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('router-link',{staticClass:"name-link",attrs:{"to":{
                          name: 'Location Detail',
                          params: { id: item.id },
                        },"target":"_blank"}},[_c('span',{staticStyle:{"color":"#464646"}},[_vm._v(_vm._s(item.name))])])]}},{key:"item.id",fn:function({ item }){return [_c('v-btn',{staticClass:"while--text red text--lighten-2 font-weight-bold",attrs:{"small":""},on:{"click":function($event){return _vm.removeFromLocation(item.id)}}},[_vm._v(" Remove ")])]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-form',{ref:"form",staticClass:"mb-3",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"5"}},[_c('carrier-auto-complete')],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"5"}},[_c('v-select',{attrs:{"label":"Network Type","items":_vm.networkOptions,"rules":[_vm.rules.required]},model:{value:(_vm.network),callback:function ($$v) {_vm.network=$$v},expression:"network"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"while--text blue text--lighten-2 font-weight-bold mt-5",class:_vm.$vuetify.breakpoint.smAndDown ? 'float-right' : '',attrs:{"small":""},on:{"click":function($event){return _vm.addToCarrier()}}},[_vm._v(" Add ")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"pt-0 mb-2",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchCarrier),callback:function ($$v) {_vm.searchCarrier=$$v},expression:"searchCarrier"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.carrierHeaders,"items":_vm.currentCustomer.carriers,"calculate-widths":"","fixed-header":"","items-per-page":5,"footer-props":{
                      'items-per-page-options': [5],
                    },"search":_vm.searchCarrier},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                          name: 'Carrier Detail',
                          params: {
                            id: item.id,
                            title: item.name,
                          },
                        },"target":"_blank"}},[_c('span',{staticStyle:{"color":"#464646"}},[_vm._v(_vm._s(item.name))])])]}},{key:"item.network",fn:function({ item }){return [_vm._v(" "+_vm._s(item.network.length ? item.network[0].type_text : "")+" ")]}},{key:"item.id",fn:function({ item }){return [_c('v-btn',{staticClass:"while--text red text--lighten-2 font-weight-bold",attrs:{"small":""},on:{"click":function($event){return _vm.removeFromCarrier(item.id)}}},[_vm._v(" Remove ")])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container fluid style="max-width: 1600px">
    <v-row>
      <!-- Quick Actions -->
      <v-col cols="12" md="4">
        <v-card min-height="390" class="mt-0 pl-0">
          <v-card-title class="blue--text text--lighten-2">
            Quick Actions
          </v-card-title>
          <v-card-text class="pb-0">
            <v-btn
              small
              color="red"
              class="mt-2"
              @click="removeExternalLoadPostings"
            >
              Remove External Load Postings
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- / Quick Actions -->
      <!-- Stats -->
      <v-col cols="12" md="4">
        <v-card min-height="390" class="mt-0">
          <v-card-title class="blue--text text--lighten-2">
            Stats
          </v-card-title>
          <v-card-text class="pb-0">
            <p class="col-md-12 mb-0 pb-0 pl-0">
              <b>Total Shipments:</b>
              {{ customerStats.totalShipment }}
            </p>
            <p class="col-md-12 mb-0 pb-0 pl-0">
              <b>Margin %:</b> {{ customerStats.averageMargin }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- / Stats -->
      <!-- Shipments -->
      <v-col cols="12" md="4">
        <v-card min-height="390" class="mt-0">
          <v-card-title class="blue--text text--lighten-2">
            Shipments
            <v-spacer />
            <v-btn
              small
              class="float-right blue--text text--lighten-2"
              text
              @click="viewAllShipments"
            >
              View All
            </v-btn>
          </v-card-title>
          <v-card-text class="pb-1">
            <div v-if="recentShipments !== null">
              <template v-for="(item, key) in recentShipments">
                <v-row
                  :key="key"
                  :style="key !== 4 ? 'border-bottom: 1px solid #E0E0E0' : ''"
                >
                  <p class="col-md-12 mb-0 pb-0 font-weight-bold">
                    {{ item.id }}
                  </p>
                  <p class="col-md-4 mb-0 pb-1 text-left">
                    <span
                      v-if="
                        item.shipper.city && item.shipper.stateProvinceRegion
                      "
                    >
                      {{
                        formatLocation(
                          item.shipper.city,
                          item.shipper.stateProvinceRegion
                        )
                      }}
                    </span>
                  </p>
                  <v-icon class="col-md-1 mb-0 pb-1 text-left">
                    mdi-arrow-right-bold
                  </v-icon>
                  <p class="col-md-4 mb-0 pb-1 text-left">
                    <span
                      v-if="
                        item.consignee.city &&
                        item.consignee.stateProvinceRegion
                      "
                    >
                      {{
                        formatLocation(
                          item.consignee.city,
                          item.consignee.stateProvinceRegion
                        )
                      }}
                    </span>
                  </p>
                  <p class="col-md-3 mb-0 pb-1 text-center">
                    <span v-if="item.customerPrice">
                      $ {{ item.customerPrice }}
                    </span>
                  </p>
                </v-row>
              </template>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- / Shipments -->
    </v-row>

    <v-row>
      <!-- Relationships -->
      <v-col cols="12">
        <v-card class="mt-0">
          <v-card-title class="blue--text text--lighten-2 pb-0">
            Relationships
          </v-card-title>
          <v-card-text>
            <v-row>
              <!-- locations -->
              <v-col cols="12" md="6">
                <v-row>
                  <!-- add location -->
                  <v-col cols="10" class="py-0">
                    <v-autocomplete
                      v-model="location"
                      :items="locationList"
                      :loading="loading"
                      :search-input.sync="search"
                      color="info"
                      clearable
                      item-text="name"
                      item-value="id"
                      label="Location"
                      return-object
                      required
                      close-on-content-click
                      item-width="200"
                      :filter="(v) => v"
                      @click:clear="removeLocation()"
                    >
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          {{ shortLocationName(data.item.name) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name" />
                          <v-list-item-subtitle
                            v-html="
                              data.item.city +
                              ', ' +
                              data.item.state_province_region
                            "
                          />
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-btn
                      small
                      class="
                        while--text
                        blue
                        text--lighten-2
                        font-weight-bold
                        float-left
                        mt-5
                      "
                      :class="
                        $vuetify.breakpoint.smAndDown ? 'float-right' : ''
                      "
                      @click="addToLocation()"
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- / add location -->
                <!-- location search -->
                <v-row>
                  <v-col cols="12" class="pt-0">
                    <v-text-field
                      v-model="searchLocation"
                      append-icon="mdi-magnify"
                      label="Search"
                      class="pt-0 mb-2"
                      single-line
                      hide-details
                    />
                  </v-col>
                </v-row>
                <!-- / location search -->
                <!-- locations table -->
                <v-row>
                  <v-col cols="12" class="pt-0">
                    <v-data-table
                      :headers="locationHeaders"
                      :items="currentCustomer.locations"
                      class="elevation-1"
                      calculate-widths
                      fixed-header
                      :items-per-page="5"
                      :footer-props="{
                        'items-per-page-options': [5],
                      }"
                      :search="searchLocation"
                    >
                      <template v-slot:item.name="{ item }">
                        <router-link
                          :to="{
                            name: 'Location Detail',
                            params: { id: item.id },
                          }"
                          class="name-link"
                          target="_blank"
                        >
                          <span style="color: #464646">{{ item.name }}</span>
                        </router-link>
                      </template>
                      <template v-slot:item.id="{ item }">
                        <v-btn
                          small
                          class="
                            while--text
                            red
                            text--lighten-2
                            font-weight-bold
                          "
                          @click="removeFromLocation(item.id)"
                        >
                          Remove
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <!-- / locations table -->
              </v-col>
              <!-- locations -->

              <!-- carriers -->
              <v-col cols="12" md="6">
                <!-- add carrier -->
                <v-form ref="form" v-model="valid" class="mb-3">
                  <v-row>
                    <v-col cols="5" class="py-0">
                      <carrier-auto-complete />
                    </v-col>

                    <v-col cols="5" class="py-0">
                      <v-select
                        v-model="network"
                        label="Network Type"
                        :items="networkOptions"
                        :rules="[rules.required]"
                      />
                    </v-col>

                    <v-col cols="2" class="py-0">
                      <v-btn
                        small
                        class="
                          while--text
                          blue
                          text--lighten-2
                          font-weight-bold
                          mt-5
                        "
                        :class="
                          $vuetify.breakpoint.smAndDown ? 'float-right' : ''
                        "
                        @click="addToCarrier()"
                      >
                        Add
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <!-- / add carrier -->
                <!-- carriers search -->
                <v-row>
                  <v-col cols="12" class="pt-0">
                    <v-text-field
                      v-model="searchCarrier"
                      append-icon="mdi-magnify"
                      label="Search"
                      class="pt-0 mb-2"
                      single-line
                      hide-details
                    />
                  </v-col>
                </v-row>
                <!-- / carriers search -->
                <!-- carriers table -->
                <v-row>
                  <v-col cols="12" class="pt-0">
                    <v-data-table
                      :headers="carrierHeaders"
                      :items="currentCustomer.carriers"
                      class="elevation-1"
                      calculate-widths
                      fixed-header
                      :items-per-page="5"
                      :footer-props="{
                        'items-per-page-options': [5],
                      }"
                      :search="searchCarrier"
                    >
                      <template v-slot:item.name="{ item }">
                        <router-link
                          :to="{
                            name: 'Carrier Detail',
                            params: {
                              id: item.id,
                              title: item.name,
                            },
                          }"
                          target="_blank"
                        >
                          <span style="color: #464646">{{ item.name }}</span>
                        </router-link>
                      </template>
                      <template v-slot:item.network="{ item }">
                        {{
                          item.network.length ? item.network[0].type_text : ""
                        }}
                      </template>
                      <template v-slot:item.id="{ item }">
                        <v-btn
                          small
                          class="
                            while--text
                            red
                            text--lighten-2
                            font-weight-bold
                          "
                          @click="removeFromCarrier(item.id)"
                        >
                          Remove
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <!-- / carriers table -->
              </v-col>
              <!-- carriers -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Relationships -->
    </v-row>
  </v-container>
</template>

<script>
import debounce from "lodash/debounce"
import common from "@/utils/common"
import api from "@/api/api"
import { mapGetters } from "vuex"
import CarrierAutoComplete from "@/views/pages/components/CarrierAutoComplete"
import validation from "@/utils/form-validation"
import { NETWORK_TYPES } from "@/assets/constants"

export default {
  name: "Summary",
  components: {
    CarrierAutoComplete,
  },
  props: {
    customerId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      locationHeaders: [
        {
          text: "Location",
          align: "start",
          sortable: false,
          value: "name",
          width: "90%",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "id",
        },
      ],
      carrierHeaders: [
        {
          text: "Carrier",
          align: "start",
          sortable: false,
          value: "name",
          width: "45%",
        },
        {
          text: "Network",
          align: "start",
          sortable: false,
          value: "network",
          width: "45%",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "id",
        },
      ],
      searchLocation: null,
      searchCarrier: null,
      loading: false,
      location: null,
      search: "",
      locations: [],
      network: null,
      networkOptions: [
        {
          text: "Non Exo Network",
          value: NETWORK_TYPES.NON_EXO,
        },
        {
          text: "Private Network",
          value: NETWORK_TYPES.PRIVATE,
        },
      ],
      rules: validation.rules,
      valid: false,
    }
  },
  computed: {
    ...mapGetters("customer", [
      "currentCustomer",
      "customerStats",
      "customerShipments",
    ]),
    ...mapGetters("carrier", ["carrierId"]),
    locationList() {
      return this.locations.map((location) => {
        const name = location.name
        return Object.assign({}, location, { name })
      })
    },
    recentShipments() {
      return this.customerShipments.results.slice(
        Math.max(this.customerShipments.results.length - 5, 0)
      )
    },
  },
  watch: {
    search(val) {
      if (!val || val.length === 0) {
        this.locations = []
        return
      }
      if (this.location) {
        if (val !== this.location.name) {
          if (this.loading) return
          this.loading = true
          this.getSearch()
        }
      } else {
        if (this.loading) return
        this.loading = true
        this.getSearch()
      }
    },
    location: function (val) {
      if (!val) {
        this.location = null
      }
    },
  },
  mounted() {
    this.$store.dispatch("customer/GET_CUSTOMER_STATS", this.customerId)
    const payload = {
      customerId: this.customerId,
      queryParams: {
        limit: 5,
        offset: 0,
      },
    }
    this.$store.dispatch("customer/GET_CUSTOMER_SHIPMENTS", payload)
  },
  methods: {
    formatLocation: common.methods.formatLocation,
    getSearch: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/locations/location-front-end-autocomplete/`,
          {
            params: {
              q: this.search,
            },
          }
        )
        .then((res) => {
          this.locations = res.data.results
          this.loading = false
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Could not retrieve locations.",
            error: err,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
          this.loading = false
        })
    }, 1000),
    viewAllShipments() {
      this.$store.commit("customer/setCurrentTab", "tab-3")
    },
    removeExternalLoadPostings() {
      const payload = {
        customerId: this.customerId,
        removePostings: true,
      }
      this.$store.dispatch("customer/REMOVE_TRUCKSTOP_POSTINGS", payload)
    },
    async addToLocation() {
      if (!this.location) {
        return
      }
      const payload = {
        id: this.location.id,
        customerCompany: this.customerId,
      }
      await this.$store.dispatch("location/UPDATE_CUSTOMER_LOCATION", payload)
      this.$store.dispatch("customer/GET_CUSTOMER_COMPANY", this.customerId)
    },
    async addToCarrier() {
      if (!this.carrierId || !this.$refs.form.validate()) {
        return
      }
      const payload = {
        id: this.carrierId,
        customerCompany: this.customerId,
        network: this.network,
      }
      await this.$store.dispatch("carrier/UPDATE_CUSTOMER_CARRIER", payload)
      this.$store.commit("carrier/setCarrierId", null)
      this.network = null
      this.$refs.form.reset()
      this.$store.dispatch("customer/GET_CUSTOMER_COMPANY", this.customerId)
    },
    async removeFromLocation(locationId) {
      if (!locationId) {
        return
      }
      const payload = {
        id: locationId,
        customerCompany: this.customerId,
      }
      await this.$store.dispatch(
        "location/DELETE_CUSTOMER_FROM_LOCATION",
        payload
      )
      this.$store.dispatch("customer/GET_CUSTOMER_COMPANY", this.customerId)
    },
    async removeFromCarrier(carrierId) {
      if (!carrierId) {
        return
      }
      const payload = {
        id: carrierId,
        customerCompany: this.customerId,
      }
      await this.$store.dispatch(
        "carrier/DELETE_CUSTOMER_FROM_CARRIER",
        payload
      )
      this.$store.dispatch("customer/GET_CUSTOMER_COMPANY", this.customerId)
    },
    removeLocation() {
      this.location = null
    },
    shortLocationName(value) {
      let sortName = ""
      if (value !== undefined && value !== null) {
        sortName = value.match(/\b(\w)/g).join("")
        sortName = sortName.charAt(0) + sortName.charAt(1)
      }
      return sortName
    },
  },
}
</script>

<style>
.v-application a {
  color: #000;
  text-decoration: none;
}
</style>

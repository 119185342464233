<template>
  <v-container fluid style="max-width: 1600px">
    <v-row>
      <v-col cols="12" md="12">
        <v-expansion-panels v-model="panels">
          <!-- Profile -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="col-md-12 blue--text text--lighten-2 font-size-14">
                <v-icon class="pl-3 pr-1 blue--text text--lighten-2" medium>
                  mdi-account-circle
                </v-icon>
                Profile
              </div>
              <div class="col-sm-10 col-md-6 col-lg-6 col-xl-6">
                <v-btn
                  class="font-weight-bold float-right"
                  small
                  style="color: #1e88e5"
                  text
                  @click.stop="updateProfile"
                >
                  <v-icon class="pr-2" medium>mdi-content-save</v-icon>
                  <span v-if="$vuetify.breakpoint.mdAndUp">Save</span>
                </v-btn>
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content v-if="profile">
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="profile.name"
                    :disabled="!hasAccess"
                    label="Name"
                    type="text"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <detail-location-auto-complete
                    :address="profile.fullAddress"
                    :disabled="!hasAccess"
                    @event="setLocation"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="profile.phone"
                    :disabled="!hasAccess"
                    label="Phone"
                    type="text"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="profile.website"
                    :disabled="!hasAccess"
                    label="Website"
                    type="text"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="profile.creditLimit"
                    :disabled="!hasAccess"
                    label="Credit Limit"
                    prepend-icon="mdi-currency-usd"
                    type="text"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="profile.utilizedCredit"
                    :disabled="!hasAccess"
                    label="Utilized Credit"
                    prepend-icon="mdi-currency-usd"
                    type="text"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="profile.quickbooksID"
                    :disabled="!hasAccess"
                    label="Quickbooks ID"
                    type="text"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <account-manager-auto-complete
                    :default-manager="profile.manager"
                    color="#e1b753"
                    @blur="selectAccountManager"
                    @event="selectAccountManager"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="mt-3" cols="12" md="3">
                  <v-text-field
                    v-model="profile.payTerms"
                    :disabled="!hasAccess"
                    :rules="[rules.intDigit]"
                    label="Pay Terms"
                    type="text"
                  />
                </v-col>
                <v-col class="mt-3" cols="12" md="3">
                  <agent-auto-complete
                    :default-agent="profile.agent"
                    color="#e1b753"
                    @blur="selectAgent"
                    @event="selectAgent"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- / Profile -->

          <!-- Contact -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="col-md-9 blue--text text--lighten-2 font-size-14">
                <v-icon class="pl-3 pr-1 blue--text text--lighten-2" medium>
                  mdi-account-box-outline
                </v-icon>
                Contact
              </div>
              <div class="col-sm-10 col-md-6 col-lg-6 col-xl-6">
                <v-btn
                  class="font-weight-bold float-right"
                  small
                  style="color: #1e88e5"
                  text
                  @click.stop="addContact"
                >
                  <v-icon class="pl-3 pr-1" medium>
                    mdi-plus-circle-outline
                  </v-icon>
                  <span v-if="$vuetify.breakpoint.mdAndUp">Add Contact</span>
                </v-btn>
                <v-btn
                  class="font-weight-bold float-right"
                  small
                  style="color: #1e88e5"
                  text
                  @click.stop="saveAllContacts"
                >
                  <v-icon class="pl-3 pr-1" medium>
                    mdi-content-save-all
                  </v-icon>
                  <span v-if="$vuetify.breakpoint.mdAndUp">Save All</span>
                </v-btn>
              </div>
            </v-expansion-panel-header>

            <template v-for="(item, key) in contacts">
              <v-expansion-panel-content :key="key" fluid>
                <v-row>
                  <v-col class="py-0" cols="12" md="4">
                    <v-text-field
                      v-model="item.name"
                      label="Name"
                      type="text"
                    />
                  </v-col>
                  <v-col class="py-0" cols="12" md="4">
                    <v-text-field
                      v-model="item.email"
                      label="Email"
                      type="text"
                    />
                  </v-col>
                  <v-col class="py-0" cols="12" md="4">
                    <v-text-field
                      v-model="item.phone"
                      label="Phone"
                      type="text"
                    />
                  </v-col>
                </v-row>

                <v-row class="mb-5">
                  <v-col class="py-0" cols="12" md="2">
                    <v-checkbox
                      v-model="item.isPrimary"
                      class="mt-0"
                      label="Primary"
                    />
                  </v-col>
                  <v-col class="py-0" cols="12" md="2">
                    <v-checkbox
                      v-model="item.pickupNotifications"
                      class="mt-0"
                      label="Pick Notification"
                    />
                  </v-col>
                  <v-col class="py-0" cols="12" md="2">
                    <v-checkbox
                      v-model="item.deliveryNotifications"
                      class="mt-0"
                      label="Delivery Notification"
                    />
                  </v-col>
                  <v-col class="py-0" cols="12" md="2">
                    <v-checkbox
                      v-model="item.driverInfoNotifications"
                      class="mt-0"
                      label="Driver Info Notification"
                    />
                  </v-col>
                  <v-col class="py-0 text-right" cols="12" md="2">
                    <v-btn
                      class="blue--text text--lighten-2 font-weight-bold mt-1"
                      small
                      text
                      @click="saveContact(key)"
                    >
                      Save
                    </v-btn>
                    <v-btn
                      class="red--text white--text font-weight-bold mt-1"
                      small
                      text
                      @click="openContactRemovingDialog(key)"
                    >
                      Remove
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </template>
          </v-expansion-panel>
          <!-- / Contact -->
        </v-expansion-panels>
      </v-col>

      <!-- Contact removing confirmation dialog -->
      <v-dialog v-model="isContactRemovingDialogOpen" max-width="290">
        <v-card>
          <v-card-text> Please confirm contact deletion </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="grey darken-1"
              text
              @click="isContactRemovingDialogOpen = false"
            >
              Cancel
            </v-btn>

            <v-btn color="orange darken-1" text @click="removeContact(index)">
              Delete contact
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- / Contact deletion confirmation dialog -->
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import validation from "@/utils/form-validation"
import AgentAutoComplete from "@/views/pages/components/AgentAutoComplete"
import DetailLocationAutoComplete from "@/views/pages/components/DetailLocationAutoComplete"
import AccountManagerAutoComplete from "@/views/pages/components/AccountManagerAutoComplete"

export default {
  name: "Details",
  components: {
    AgentAutoComplete,
    DetailLocationAutoComplete,
    AccountManagerAutoComplete,
  },
  props: {
    customerId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      rules: validation.rules,
      overlay: false,
      isContactRemovingDialogOpen: false,
      index: -1,
      currentStatus: 1,
      panels: 0,
      profile: {
        name: "",
        address: "",
        phone: "",
        website: "",
        creditLimit: "",
        utilizedCredit: "",
        quickbooksID: "",
        fullAddress: {},
      },
      hasAccess: true,
      contacts: [],
    }
  },
  computed: {
    ...mapGetters("customer", ["currentCustomer", "customerContacts"]),
  },
  watch: {
    currentCustomer() {
      this.profile = this.currentCustomer
      this.profile.agent = {
        id: this.currentCustomer.accountOwner,
        name: this.currentCustomer.accountOwnerDisplay,
        text: this.currentCustomer.accountOwnerDisplay,
      }
      this.profile.manager = {
        id: this.currentCustomer.accountManager,
        name: this.currentCustomer.accountManagerDisplay,
        text: this.currentCustomer.accountManagerDisplay,
      }
      this.profile.fullAddress = {
        zipcode: this.profile.usZipcode || this.profile.caPostalCode,
        address: this.profile.address,
        city: this.profile.city,
        state: this.profile.state || this.profile.caProvince,
        country: this.profile.country,
      }
    },
    customerContacts() {
      this.contacts = this.customerContacts.results
    },
  },
  mounted() {
    this.getCustomerDetail()
  },
  methods: {
    selectAgent(value) {
      this.profile.accountOwner = value.agent.id
    },
    selectAccountManager(value) {
      this.profile.accountManager = value.manager.id
    },
    setLocation(value) {
      const {
        city = "",
        stateCode = "",
        countryCode = "",
        street = "",
        houseNumber = "",
        postalCode = "",
        state = "",
      } = value.address

      this.profile = {
        ...this.profile,
        city: city,
        state: countryCode === "USA" ? stateCode : "",
        country: countryCode,
        address: `${houseNumber ? houseNumber + " " : ""}${street}`,
        usZipcode: countryCode === "USA" ? postalCode : "",
        caProvince: countryCode === "CAN" ? state : "",
        caPostalCode: countryCode === "CAN" ? postalCode : "",
      }
    },
    getCustomerDetail() {
      this.$store.dispatch("customer/GET_CUSTOMER_COMPANY", this.customerId)
      const contactPayload = `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/contact/${this.customerId}/`
      this.$store.dispatch("customer/GET_CUSTOMER_CONTACTS", contactPayload)
    },
    updateProfile() {
      let payload = {}
      if (this.profile.id) {
        payload = {
          id: this.profile.id,
          customer: {
            name: this.profile.name,
            account_owner: this.profile.accountOwner,
            parent: this.profile.parent,
            pay_terms: this.profile.payTerms,
            organization_level: this.profile.organizationLevel,
            website: this.profile.website,
            phone: this.profile.phone,
            address: this.profile.address,
            city: this.profile.city,
            state: this.profile.state,
            us_zipcode: this.profile.usZipcode,
            ca_province: this.profile.caProvince,
            ca_postal_code: this.profile.caPostalCode,
            country: this.profile.country,
            credit_limit: this.profile.creditLimit,
            utilized_credit: this.profile.utilizedCredit,
            quickbooks_id: this.profile.quickbooksID,
            account_manager: this.profile.accountManager,
          },
        }
        this.$store.dispatch("customer/UPDATE_CUSTOMER_COMPANY", payload)
      }
    },
    addContact() {
      this.contacts.push({
        id: 0,
        createdAt: null,
        updatedAt: null,
        agentCompany: null,
        name: "",
        phone: "",
        email: "",
        isPrimary: false,
        pickupNotifications: false,
        deliveryNotifications: false,
        driverInfoNotifications: false,
        role: null,
      })
    },
    saveAllContacts() {
      if (this.contacts.length > 0) {
        for (let i = 0; i < this.contacts.length; i++) {
          this.saveContact(i)
        }
      }
    },
    saveContact(value) {
      let contactPayload = {}
      if (this.contacts[value].id === 0) {
        const profileContact = this.contacts[value]
        contactPayload = {
          customerId: this.profile.id,
          contact: {
            name: profileContact.name,
            phone: profileContact.phone,
            email: profileContact.email,
            is_primary: profileContact.isPrimary,
            pickup_notifications: profileContact.pickupNotifications,
            delivery_notifications: profileContact.deliveryNotifications,
            driver_info_notifications: profileContact.driverInfoNotifications,
            role: 1,
          },
        }
        this.$store.dispatch("customer/ADD_CUSTOMER_CONTACT", contactPayload)
      } else {
        const profileContact = this.contacts[value]
        contactPayload = {
          customerId: this.profile.id,
          contactId: profileContact.id,
          contact: {
            name: profileContact.name,
            phone: profileContact.phone,
            email: profileContact.email,
            is_primary: profileContact.isPrimary,
            pickup_notifications: profileContact.pickupNotifications,
            delivery_notifications: profileContact.deliveryNotifications,
            driver_info_notifications: profileContact.driverInfoNotifications,
            role: 1,
          },
        }
        this.$store.dispatch("customer/UPDATE_CUSTOMER_CONTACT", contactPayload)
      }
    },
    openContactRemovingDialog(index) {
      this.isContactRemovingDialogOpen = true
      this.index = index
    },
    removeContact(index) {
      if (this.contacts[index].id > 0) {
        const payload = {
          contactId: this.contacts[index].id,
        }
        this.$store.dispatch("customer/DELETE_CUSTOMER_CONTACT", payload)
      }
      this.contacts.splice(index, 1)
      this.isContactRemovingDialogOpen = false
    },
  },
}
</script>

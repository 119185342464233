<template>
  <div>
    <!-- single field address input  -->
    <v-row v-if="!isCustomAddressFormOpen">
      <v-col cols="12" class="ma-0 pb-0">
        <v-autocomplete
          v-model="selectedPlace"
          :items="matchedPlacesList"
          item-text="title"
          item-value="title"
          :loading="isLoading"
          :disabled="disabled"
          :search-input.sync="query"
          clearable
          no-filter
          return-object
          color="#e1b753"
          label="Address"
          data-cy="location-address-field"
          @update:search-input="onSearchInputChange"
          @change="setSelectedPlace"
          @click:clear="removePlace()"
        />
      </v-col>
    </v-row>
    <!-- / single field address input  -->

    <!-- custom address input  -->
    <!-- used if Here Map service couldn't find an address -->
    <div v-if="isCustomAddressFormOpen">
      <v-row>
        <v-col :cols="12" class="pb-0">
          <v-text-field
            v-model="selectedPlace.address.street"
            label="House number + Street address"
            data-cy="location-manual-edit-house-street-field"
            @input="setCustomSelectedPlace()"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="selectedPlace.address.city"
            label="City"
            @input="setCustomSelectedPlace()"
          />
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="selectedPlace.address.stateCode"
            label="State"
            @input="setCustomSelectedPlace()"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" class="py-0">
          <v-text-field
            v-model="selectedPlace.address.postalCode"
            label="Zipcode"
            @input="setCustomSelectedPlace()"
          />
        </v-col>
        <v-col cols="6" class="py-0">
          <v-select
            v-model="selectedPlace.address.countryCode"
            :items="countryCodes"
            label="Country"
            item-text="name"
            item-value="id"
            @input="setCustomSelectedPlace()"
          />
        </v-col>
      </v-row>
    </div>
    <!-- / custom address input  -->

    <v-tooltip :disabled="isPlaceSelected" right>
      <template v-slot:activator="{ on }">
        <div v-on="on" class="d-inline-block">
          <v-checkbox
            v-model="isCustomAddressFormOpen"
            :disabled="!isPlaceSelected"
            color="primary"
            dense
            hide-details
            label="Edit the address manually"
            class="my-O pt-0 pb-1"
            data-cy="location-manual-edit-checkbox"
          />
        </div>
      </template>
      <span>Enter the nearest address</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Vue from "vue"
import debounce from "lodash/debounce"

export default {
  name: "DetailLocationAutoComplete",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    countryCode: {
      type: String,
      default: "CAN,MEX,USA",
    },
    address: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      hereMapPlatform: null,
      query: "",
      matchedPlacesList: [],
      emptySelectedPlace: {
        id: "",
        title: "",
        address: {
          street: "",
          city: "",
          stateCode: "",
          postalCode: "",
          countryCode: "",
        },
        position: {
          lat: 0,
          lng: 0,
        },
      },
      selectedPlace: {
        id: "",
        title: "",
        address: {
          street: "",
          city: "",
          stateCode: "",
          postalCode: "",
          countryCode: "",
        },
        position: {
          lat: 0,
          lng: 0,
        },
      },
      isCustomAddressFormOpen: false,
      isInit: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters("generic", ["countries"]),
    countryCodes() {
      return this.countries.map((country) => {
        if (country.id === "MX") {
          return {
            id: "MEX",
            name: country.name,
          }
        } else {
          return country
        }
      })
    },
    isPlaceSelected() {
      return Boolean(this.selectedPlace && this.selectedPlace.id)
    },
  },
  watch: {
    address: {
      immediate: true,
      handler(addressProp) {
        if (addressProp) {
          this.isInit = true
          const address = {
            street: addressProp.address,
            city: addressProp.city,
            stateCode: addressProp.state,
            postalCode: addressProp.zipcode,
            countryCode: addressProp.country,
          }
          const serializedAddress = this.serializeAddress(address)
          Vue.nextTick(() => {
            this.selectedPlace = {
              id: serializedAddress,
              title: serializedAddress,
              address: {
                label: serializedAddress,
                countryCode: this.formatCountryCodeForSelect(
                  address.countryCode
                ),
                stateCode: address.stateCode,
                postalCode: address.postalCode,
                city: address.city,
                street: address.street,
              },
              position: {
                lat: addressProp.latitude,
                lng: addressProp.longitude,
              },
            }
            this.matchedPlacesList = [this.selectedPlace]
          })
        } else {
          this.selectedPlace = this.emptySelectedPlace
        }
      },
    },
  },
  mounted() {
    this.initHereMapPlatform()
    this.matchedPlacesList = []
  },
  methods: {
    initHereMapPlatform() {
      this.hereMapPlatform = new window.H.service.Platform({
        apikey: process.env.VUE_APP_HERE_MAP_KEY,
      })
    },
    onSearchInputChange(query) {
      if (!query) {
        return
      }
      if (this.isInit) {
        this.isInit = false
        return
      }
      this.isLoading = true
      this.fetchGeoData(query)
    },

    setCustomSelectedPlace() {
      const address = {
        countryCode: this.formatCountryCodeForBackend(
          this.selectedPlace.address.countryCode
        ),
        stateCode: this.selectedPlace.address.stateCode,
        postalCode: this.selectedPlace.address.postalCode,
        city: this.selectedPlace.address.city,
        street: this.selectedPlace.address.street,
      }
      const serializedAddress = this.serializeAddress(address)
      this.selectedPlace.title = serializedAddress
      this.selectedPlace.address.label = serializedAddress
      this.emitEvent()
    },

    setSelectedPlace(selectedPlace) {
      if (selectedPlace) {
        this.selectedPlace = {
          id: selectedPlace.id,
          title: selectedPlace.title,
          address: {
            label: selectedPlace.address.label,
            countryCode: this.formatCountryCodeForBackend(
              selectedPlace.address.countryCode
            ),
            stateCode: selectedPlace.address.stateCode,
            postalCode: selectedPlace.address.postalCode,
            city: selectedPlace.address.city,
            street: `${selectedPlace.address.houseNumber || ""}${
              selectedPlace.address.houseNumber ? " " : ""
            }${selectedPlace.address.street}`,
          },
          position: {
            lat: selectedPlace.position.lat,
            lng: selectedPlace.position.lng,
          },
        }
      } else {
        this.selectedPlace = this.emptySelectedPlace
      }
      this.emitEvent()
    },

    emitEvent() {
      const selectedPlaceQueryObject = {
        title: this.selectedPlace.title,
        id: this.selectedPlace.id,
        resultType: "houseNumber",
        houseNumberType: "interpolated",
        address: {
          label: this.selectedPlace.address.label,
          country: this.formatCountryCodeForBackend(
            this.selectedPlace.address.countryCode
          ),
          countryCode: this.formatCountryCodeForBackend(
            this.selectedPlace.address.countryCode
          ),
          countryName: this.selectedPlace.address.countryCode,
          stateCode: this.selectedPlace.address.stateCode,
          state: this.selectedPlace.address.stateCode,
          stateProvinceRegion: this.selectedPlace.address.stateCode,
          city: this.selectedPlace.address.city,
          postalCode: this.selectedPlace.address.postalCode,
          street: this.selectedPlace.address.street,
        },
        position: {
          lat: this.selectedPlace.position.lat,
          lng: this.selectedPlace.position.lng,
        },
        /* Unused properties. Need to pass backend schema validation */
        access: [
          {
            lat: this.selectedPlace.position.lat,
            lng: this.selectedPlace.position.lng,
          },
        ],
        mapView: {
          west: this.selectedPlace.position.lng,
          south: this.selectedPlace.position.lat,
          east: this.selectedPlace.position.lng,
          north: this.selectedPlace.position.lat,
        },
        scoring: {
          queryScore: 1,
          fieldScore: {
            city: 1,
            streets: [1],
            houseNumber: 1,
          },
        },
        /* / Unused properties. Need to pass backend schema validation */
      }
      this.$emit("event", selectedPlaceQueryObject)
    },

    removePlace() {
      this.matchedPlacesList = []
    },

    fetchGeoData: debounce(function (query) {
      const searchService = this.hereMapPlatform.getSearchService()
      const geocodingParameters = {
        q: query,
        in: `countryCode:${this.countryCode}`,
      }
      searchService.geocode(
        geocodingParameters,
        // on success
        (result) => {
          this.isLoading = false
          this.matchedPlacesList = result.items
        },
        // on error
        () => {
          this.isLoading = false
          this.matchedPlacesList = []
          alert("Can't reach the remote server")
        }
      )
    }, 500),

    serializeAddress(address) {
      return `${address.street ? this.capitalize(address.street) + ", " : ""}${
        address.city ? this.capitalize(address.city) + ", " : ""
      }${address.stateCode ? address.stateCode + " " : ""}${
        address.postalCode ? address.postalCode + ", " : ""
      }${address.countryCode || ""}`
    },

    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    formatCountryCodeForBackend(countryCode) {
      return countryCode === "MEX" ? "MX" : countryCode
    },

    formatCountryCodeForSelect(countryCode) {
      return countryCode === "MX" ? "MEX" : countryCode
    },
  },
}
</script>

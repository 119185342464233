<template>
  <v-container fluid style="max-width: 1600px">
    <v-row>
      <v-col cols="12">
        <load-tracking />
        <v-data-table
          id="tblShipments"
          :headers="headers"
          :items="shipments"
          :items-per-page="pageSize"
          calculate-widths
          class="elevation-1"
          fixed-header
          hide-default-footer
        >
          <template v-slot:item.id="{ item }">
            <v-btn
              :to="{
                name: 'Load Detail',
                params: { id: item.id },
              }"
              small
              target="_blank"
              text
            >
              {{ item.id }}
            </v-btn>
          </template>
          <template v-slot:item.loadStatus="{ item }">
            <load-status-button
              :agent-detail="agentDetail"
              :load-status-items="loadStatusChoices"
              :shipment-detail="item"
            />
          </template>
          <template v-slot:item.shipper="{ item }">
            <p v-if="item.shipper !== null">
              <span>{{
                formatLocation(
                  item.shipper.city,
                  item.shipper.stateProvinceRegion
                )
              }}</span>
              <br />
              <span class="subtitle">{{ item.pickupDate }}</span>
            </p>
          </template>
          <template v-slot:item.consignee="{ item }">
            <p v-if="item.consignee !== null">
              <span class="font-weight-bold">{{
                formatLocation(
                  item.consignee.city,
                  item.consignee.stateProvinceRegion
                )
              }}</span>
              <br />
              <span class="subtitle">{{ item.deliveryDate }}</span>
            </p>
          </template>
          <template v-slot:item.margin="{ item }">
            <p v-if="item.margin !== null" class="mb-0">{{ item.margin }}%</p>
          </template>
          <template v-slot:item.customerPrice="{ item }">
            <p v-if="item.customerPrice !== null" class="mb-0">
              ${{ item.customerPrice }}
            </p>
          </template>
          <template v-slot:item.carrierPrice="{ item }">
            <p v-if="item.carrierPrice !== null" class="mb-0">
              ${{ item.carrierPrice }}
            </p>
          </template>
          <template v-slot:footer>
            <v-page
              :page-size-menu="[10, 20, 50, 100]"
              :total-row="totalRecord"
              class="pt-3 pr-3 pb-3"
              @page-change="readDataFromAPI"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import common from "@/utils/common"
import LoadTracking from "@/views/pages/LoadTracking"
import LoadStatusButton from "@/views/pages/components/LoadStatusButton"
export default {
  name: "Shipments",
  components: {
    LoadStatusButton,
    LoadTracking,
  },
  props: {
    customerId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      headers: [
        { text: "Load Number", value: "id", align: "center" },
        { text: "Status", value: "loadStatus", align: "center" },
        { text: "Origin", value: "shipper", align: "center" },
        { text: "Destination", value: "consignee", align: "center" },
        { text: "Equipment Type", value: "equipmentType" },
        {
          text: "Customer Pay",
          value: "customerPrice",
          align: "right",
        },
        { text: "Carrier Pay", value: "carrierPrice", align: "right" },
        { text: "Margin", value: "margin", align: "right" },
      ],
      shipments: [],
      totalRecord: 0,
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
      queryParams: {},
    }
  },
  computed: {
    ...mapGetters("customer", ["customerShipments"]),
    ...mapGetters("loadDetail", ["loadStatusChoices", "loadStatusUpdate"]),
    ...mapGetters("generic", ["agentDetail"]),
  },
  watch: {
    customerShipments() {
      this.totalRecord = this.customerShipments.count
      this.shipments = this.customerShipments.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
    loadStatusUpdate() {
      if (this.loadStatusUpdate !== null) {
        const idx = this.shipments.findIndex(
          (x) => x.id === this.loadStatusUpdate.id
        )
        this.shipments[idx].loadStatus = this.loadStatusUpdate.loadStatusDisplay
      }
    },
  },
  mounted() {
    this.shipments = []
    this.getShipments()
  },
  methods: {
    formatLocation: common.methods.formatLocation,
    getShipments() {
      this.shipments = []
      this.queryParams = {}
      this.queryParams.limit = this.pageSize
      this.queryParams.offset = 0
    },
    readDataFromAPI(value) {
      this.loading = true
      this.page = value.pageNumber
      this.pageSize = value.pageSize
      let pageNumber = this.pageSize * this.page - this.pageSize
      if (pageNumber < 0) {
        pageNumber = 0
      }
      this.queryParams.offset = pageNumber
      this.queryParams.limit = this.pageSize
      const payload = {
        customerId: this.customerId,
        queryParams: this.queryParams,
      }
      this.$store.dispatch("customer/GET_CUSTOMER_SHIPMENTS", payload)
    },
  },
}
</script>

<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ a {
  text-decoration: none;
  cursor: pointer;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.v-btn:not(.v-btn--round).v-size--small {
  font-size: 12px;
}
</style>

<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>

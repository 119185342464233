<template>
  <v-container fluid style="max-width: 1600px">
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="mt-0">
          <v-container class="blue-grey lighten-5">
            <v-toolbar-title> Notes </v-toolbar-title>
          </v-container>
          <v-container style="height: 300px; overflow-y: auto">
            <v-card-text>
              <div v-for="(item, index) in contents" :key="index">
                <p class="grey--text text-caption mb-2">
                  {{ format_date(item.updatedAt) }}
                </p>
                <p
                  class="text-body-1 mb-2"
                  v-html="item.note.replace(/(?:\r\n|\r|\n)/g, '<br>')"
                />
                <v-card-actions class="pl-0 pt-0">
                  <v-btn text small color="normal" @click="editNote(item.id)">
                    Edit
                  </v-btn>
                  <v-btn
                    text
                    small
                    color="error"
                    @click="confirmDeleteNote(item.id)"
                  >
                    Delete
                  </v-btn>
                </v-card-actions>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <v-card class="mt-0">
          <v-container class="blue-grey lighten-5">
            <v-toolbar-title> Create Note </v-toolbar-title>
          </v-container>
          <v-container style="height: 300px">
            <v-textarea
              v-model="noteDetail.note"
              rows="5"
              class="ml-2 pt-0"
              clearable
              no-resize
            />
            <v-btn
              class="blue white--text font-weight-bold offset-md-9"
              @click="saveNote()"
            >
              Save Note
            </v-btn>
            <v-btn
              class="gray white--text font-weight-bold ml-2"
              @click="cancelNote()"
            >
              Cancel
            </v-btn>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-text> Please confirm note deletion </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="grey darken-1" text small @click="dialog = false">
            Cancel
          </v-btn>

          <v-btn color="orange darken-1" text small @click="removeRecord()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import moment from "moment"
export default {
  name: "Notes",
  props: {
    customerId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      note: "",
      dialog: false,
      editNoteId: 0,
      contents: [
        {
          id: 0,
          createdAt: "2020/10/01 08:00 PDT",
          updatedAt: "2020/10/01 08:00 PDT",
          note: "Simple dummy text",
        },
      ],
      noteDetail: {
        id: 0,
        createdAt: null,
        updatedAt: null,
        createdByDisplay: null,
        note: "",
      },
    }
  },
  computed: {
    ...mapGetters("customer", {
      customerNotes: "customerNotes",
      customerNoteDetail: "customerNoteDetail",
    }),
  },
  watch: {
    customerNotes() {
      this.contents = this.customerNotes.results
    },
    customerNoteDetail() {
      this.noteDetail = this.customerNoteDetail
    },
  },
  mounted() {
    this.loadNotes()
  },
  methods: {
    loadNotes() {
      const notePayload = `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/note/${this.customerId}/?limit=100&offset=0`
      this.$store.dispatch("customer/GET_CUSTOMER_NOTES", notePayload)
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM-DD-YYYY hh:mm:ss")
      }
    },
    editNote(noteId) {
      this.$store.dispatch("customer/GET_CUSTOMER_NOTE_DETAIL", noteId)
    },
    confirmDeleteNote(noteId) {
      this.editNoteId = noteId
      this.dialog = true
    },
    removeRecord() {
      const payload = {
        noteId: this.editNoteId,
        noteURL: `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/note/${this.customerId}/?limit=100&offset=0`,
      }
      this.$store.dispatch("customer/DELETE_CARRIER_NOTE", payload)
      this.dialog = false
    },
    saveNote() {
      if (this.noteDetail.id === 0) {
        // Add new note
        const notePayload = {
          id: this.customerId,
          text: this.noteDetail.note,
        }
        this.$store.dispatch("customer/ADD_CUSTOMER_NOTE", notePayload)
      } else {
        // update note
        const notePayload = {
          id: this.noteDetail.id,
          text: this.noteDetail.note,
          customerId: this.customerId,
        }
        this.$store.dispatch("customer/UPDATE_CUSTOMER_NOTE", notePayload)
      }
      this.cancelNote()
    },
    cancelNote() {
      this.noteDetail = {
        id: 0,
        createdAt: null,
        updatedAt: null,
        createdByDisplay: null,
        note: "",
      }
    },
  },
}
</script>

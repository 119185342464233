<template>
  <div>
    <v-container fluid style="max-width: 1600px" class="pt-0">
      <v-card class="mb-0">
        <div class="pa-2 header-banner">
          <v-row>
            <div class="col-md-6 d-flex align-center">
              <div
                class="
                  white
                  rounded-circle
                  d-inline-block
                  align-center
                  text-center
                  short-name
                "
              >
                <div
                  class="transition-swing font-weight-bold grey--text"
                  style="font-size: 48px !important"
                >
                  {{ shortCustomerName }}
                </div>
              </div>
              <div class="white--text ml-4 mt-4">
                <v-list-item-title class="mb-1" style="font-size: 24px">
                  {{ currentCustomer.name }}
                </v-list-item-title>
                <div style="font-size: 18px">
                  {{ currentCustomer.address }}
                </div>
                <div style="font-size: 18px">
                  {{
                    formatLocation(currentCustomer.city, currentCustomer.state)
                  }}
                </div>
                <v-switch v-model="currentStatus" color="secondary">
                  <template v-slot:label>
                    <span class="white--text text-h4"
                      >Customer Status:
                      {{ currentStatus ? "Active" : "Inactive" }}</span
                    >
                  </template>
                </v-switch>
              </div>
            </div>
            <div
              class="
                col-md-6
                white--text
                mt-5
                mb-3
                pl-4
                pr-10
                text-h3 text-md-right
              "
            >
              Account Owner:
              <span class="font-weight-medium">
                {{ currentCustomer.accountOwnerDisplay }}
              </span>
            </div>
          </v-row>
        </div>
        <div>
          <v-tabs v-model="currentTab" centered @change="changeTab">
            <v-tab href="#tab-1"> Summary </v-tab>
            <v-tab href="#tab-2"> Details </v-tab>
            <v-tab href="#tab-3"> Shipments </v-tab>
            <v-tab href="#tab-4"> Documents </v-tab>
            <v-tab href="#tab-5"> Notes </v-tab>
          </v-tabs>
        </div>
        <v-progress-linear :active="loadingIcon" color="amber" indeterminate />
      </v-card>
    </v-container>

    <v-tabs-items v-model="currentTab" style="background: none">
      <v-tab-item value="tab-1">
        <customer-summary :customer-id="id" />
      </v-tab-item>
      <v-tab-item value="tab-2">
        <customer-details :customer-id="id" />
      </v-tab-item>
      <v-tab-item value="tab-3">
        <customer-shipments ref="shipmentTab" :customer-id="id" />
      </v-tab-item>
      <v-tab-item value="tab-4">
        <customer-documents :customer-id="id" />
      </v-tab-item>
      <v-tab-item value="tab-5">
        <customer-notes :customer-id="id" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import CustomerSummary from "@/views/pages/customer-profile/Summary"
import CustomerDetails from "@/views/pages/customer-profile/Details"
import CustomerDocuments from "@/views/pages/customer-profile/Documents"
import CustomerShipments from "@/views/pages/customer-profile/Shipments"
import CustomerNotes from "@/views/pages/customer-profile/Notes"
import common from "@/utils/common"
export default {
  name: "CustomerDetail",
  components: {
    CustomerSummary,
    CustomerDetails,
    CustomerDocuments,
    CustomerShipments,
    CustomerNotes,
  },
  props: {
    id: {
      type: String,
      default: "10",
    },
  },
  data() {
    return {
      currentTab: "tab-1",
      loadStatusItems: [],
      currentStatus: null,
    }
  },
  computed: {
    ...mapGetters("customer", {
      currentCustomer: "currentCustomer",
      customerStats: "customerStats",
      selectedTab: "selectedTab",
    }),
    ...mapGetters("generic", {
      loadingIcon: "loadingIcon",
    }),
    shortCustomerName() {
      let shortName = ""
      if (this.currentCustomer.name !== undefined) {
        common.methods.setPageTitle(this.currentCustomer.name)
        shortName = this.currentCustomer.name.match(/\b(\w)/g).join("")
        shortName = shortName.charAt(0) + shortName.charAt(1)
      }
      return shortName
    },
  },
  watch: {
    selectedTab() {
      this.currentTab = this.selectedTab
    },
    currentCustomer() {
      this.currentStatus = this.currentCustomer.active
    },
    currentStatus(newVal, oldVal) {
      if (oldVal !== null) this.updateCustomerProfile()
    },
  },
  mounted() {
    this.getCustomerDetail()
    if (this.$route.params.title !== undefined) {
      localStorage.pageTitle = this.$route.params.title
    }
  },
  methods: {
    updateCustomerProfile() {
      const payload = {
        id: this.currentCustomer.id,
        customer: {
          name: this.currentCustomer.name,
          account_owner: this.currentCustomer.accountOwner,
          parent: this.currentCustomer.parent,
          organization_level: this.currentCustomer.organizationLevel,
          website: this.currentCustomer.website,
          phone: this.currentCustomer.phone,
          address: this.currentCustomer.address,
          city: this.currentCustomer.city,
          state: this.currentCustomer.state,
          us_zipcode: this.currentCustomer.usZipcode,
          ca_province: this.currentCustomer.caProvince,
          ca_postal_code: this.currentCustomer.caPostalCode,
          country: this.currentCustomer.country,
          credit_limit: this.currentCustomer.creditLimit,
          utilized_credit: this.currentCustomer.utilizedCredit,
          quickbooks_id: this.currentCustomer.quickbooksID,
          active: this.currentStatus,
        },
      }
      this.$store.dispatch("customer/UPDATE_CUSTOMER_COMPANY", payload)
    },
    formatLocation: common.methods.formatLocation,
    getCustomerDetail() {
      this.$store.dispatch("customer/GET_CUSTOMER_COMPANY", this.id)
    },
    changeTab(value) {
      if (value === "tab-3") {
        if (this.$refs.shipmentTab !== undefined) {
          this.$refs.shipmentTab.getShipments()
        }
      }
    },
  },
}
</script>

<style scoped>
.header-banner {
  background: linear-gradient(
      0deg,
      rgba(30, 136, 229, 0.72),
      rgba(30, 136, 229, 0.72)
    ),
    #fdfdfd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px 6px 0 0;
}
.short-name {
  border: 2px solid rgb(30, 136, 229) !important;
  width: 127px;
  height: 127px;
  line-height: 127px;
}
</style>
